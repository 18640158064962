import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    standalone: true,
    imports: [NgOptimizedImage, CommonModule],
    selector: 'app-box',
    templateUrl: './box.component.html',
    styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
    @Input() logoHeader = false;
    @Input() radius = 0;
    @Input() noBodyPadding = false;
    @Input() logoWidth = 0;
    @Input() logoHeight = 0;
    @Input() logoText = '';

    public shouldDisplayRI = false;
    ngOnInit(): void {
        const currentDomain = window.location.hostname;

        if (currentDomain === 'platform.riforestry.com') {
            this.shouldDisplayRI = true;
        }
    }
}
