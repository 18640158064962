<div
    class="box-container"
    [ngStyle]="{
        'borderRadius.px': radius,
    }">
    @if (logoHeader) {
        <div class="box-container__header">
            @if (!shouldDisplayRI) {
                <img
                    ngSrc="../../../../assets/gray-logo.svg"
                    [width]="logoWidth"
                    [height]="logoHeight"
                    alt="Quiron logo"
                    priority />
            } @else {
                <span>{{ logoText }}</span>
            }
        </div>
    }
    <div
        [ngClass]="{
            'box-container__body': true,
            'box-container__body--no-padding': noBodyPadding,
        }">
        <ng-content></ng-content>
    </div>
</div>
